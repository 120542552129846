import React, { useState, useEffect } from "react";

// api
import { mainApi, paymentApi } from "Utils/api/api";

//lib
import { scrollTop } from "Utils/lib/helper";


import * as PortOne from "@portone/browser-sdk/v2";


import jsonData from "Assets/contents/contents.json";


// img

const SubscribeLogic = ({ loading, setLoading, url }) => {
  // 기본 state 관리
  const [state, setState] = useState({
    data_list: [],
    data_info: [],
    payment_info: [],
    child: [],
    modal: { open: false, type: "", id: "" },
    step: 1,
  });
  // 데이터 저장 state 관리
  const [inputData, setInputData] = useState({
    child: [],
    card_name: "",
    card_num01: "",
    card_num02: "",
    card_num03: "",
    card_num04: "",
    card_birth: "",
    card_expiry_month: "",
    card_expiry_year: "",
    card_pwd_2digit: "",
  });
  const [filterList, setFilterList] = useState({
    year_list: [...Array(30)].map((i, idx) => {
      return {
        value: parseInt(new Date().getFullYear()) + idx,
        label: `${parseInt(new Date().getFullYear()) + idx} 년`,
      };
    }), // 올해에서 앞으로 30년 까지
    month_list: [...Array(12)].map((i, idx) => {
      return {
        value: idx > 9 ? idx + 1 : `0${idx + 1}`,
        label: `${idx + 1} 월`,
      };
    }),
  });

  // content box on/off state 관리
  const [contentOpen, setContentOpen] = useState({
    period: false, // 구독 기간
    child_select: false, // 자녀 선택
    payment: false, // 결제 금액
    methods: false, // 결제 수단
  });

  //  content on/off 기능
  const setContentsOnoff = (key, value) => {
    setContentOpen({ ...contentOpen, [key]: value });
  };

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (url.type === "payment") {
      getPaymentInfo();
    } else {
      getList();
    }
  }, [url]);
  useEffect(() => {
    scrollTop();
  }, [state.step]);

  ////////////////  API  ////////////////
  // 목록 조회
  const getList = async () => {
    try {
      const {
        data: { data },
      } = await mainApi.getSubscribeList();
      setState({
        ...state,
        data_list: data.products,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // 결제 조회
  const getPaymentInfo = async () => {
    try {
      const { data } = await paymentApi.getPayment({ id: parseInt(url.id) });
      setState({
        ...state,
        data_info: data.data,
        loading: false,
        modal: { open: false, type: "", id: "" },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onClickLink = async (type) => {
    if (type === "signup") {
      if (localStorage.getItem("user_token")) {
        setStateValue("alert", {
          title: "이미 가입된 회원입니다.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } else {
        window.location.href = "/signup/info";
      }
    } else {

      /*
      if (localStorage.getItem("type") === "child") {
        setStateValue("alert", {
          title: "결제는 학부모 회원만 가능합니다.",
          sub: "학부모 계정으로 로그인하여 결제해주시길 바랍니다.",
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
      } else {
        window.location.href = `/subscribe/payment/${type}`;
      }
      */

      if (localStorage.getItem("type") !== "parents") {
        setStateValue("alert", {
          title: "결제는 학부모 회원만 가능합니다.",
          sub: "학부모 계정으로 로그인하여 결제해주시길 바랍니다.",
          isOk: () => window.location.href = `/login/student`,
          isCancel: null,
        });
      } else {
        window.location.href = `/subscribe/payment/${type}`;
      }

    }
  };
  // 결제 수단 등록
  const setPaymentCard = async () => {
    try {
      if (
        inputData.card_num01 === "" ||
        inputData.card_num02 === "" ||
        inputData.card_num03 === "" ||
        inputData.card_num04 === ""
      ) {
        setStateValue("alert", {
          title: "카드번호를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (
        inputData.card_expiry_year === "" ||
        inputData.card_expiry_month === ""
      ) {
        setStateValue("alert", {
          title: "카드번호를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.birth === "") {
        setStateValue("alert", {
          title: "생년월일을 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      if (inputData.pwd_2digit === "") {
        setStateValue("alert", {
          title: "비밀번호 앞 2자리를 입력해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }
      const json = {
        card_number: `${inputData.card_num01}-${inputData.card_num02}-${inputData.card_num03}-${inputData.card_num04}`,
        expiry: `${inputData.card_expiry_year}-${inputData.card_expiry_month}`,
        birth: inputData.card_birth,
        pwd_2digit: inputData.card_pwd_2digit,
      };
      const { data } = await paymentApi.addCardInfo(json);
      getPaymentInfo();
    } catch (error) {
      console.log(error);
      setStateValue("alert", {
        title: "카드 등록이 실패하였습니다.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };
  



    // 결제하기
    const addPayment = async () => {


      console.log("inputData.child : ", inputData.child);

      if (inputData.child.length === 0) {
        setStateValue("alert", {
          title: "아이를 선택해주세요.",
          sub: null,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });
        return;
      }

    
      try {

        let paymentId = "P"+crypto.randomUUID()
        .replace(/-/g, "")
        .slice(0, -1);


        console.log("paymentId", paymentId);
        console.log(">>> inputData.child : ", inputData.child);

        const childParams = new URLSearchParams();
        inputData.child.forEach((value) => {
          childParams.append('child', value);
        });

        const response = await PortOne.requestPayment({
          // Store ID 설정
          storeId: "store-8f32d3b0-86ad-4933-961d-0bc067fb8980",
          // 채널 키 설정
          channelKey: "channel-key-deea2444-2770-45ea-87c1-b5233dec894a",
          paymentId: paymentId,
          orderName: "단건결제",
          totalAmount: state.data_info.bill.price,
          currency: "CURRENCY_KRW", 
          payMethod: "CARD",
          redirectUrl: `${window.location.origin}/mobipayment/${parseInt(url.id)}?${childParams.toString()}`
        });


      
        console.log("response", response);

        if (response.code != null) {
          // 오류 발생
          return alert(response.message);
        }

        const { data } = await paymentApi.newSetPayment(
          { id: parseInt(url.id) },
          { 
            child: inputData.child,
            paymentId: paymentId,
            txId:response.txId
          }
        );


        console.log("final paymentId", paymentId);
        console.log("final response", data);

          if(data.code == "200"){

            setStateValue("alert", {
              title: "결제에 성공하였습니다.",
              sub: "이제 로그아웃 하신 후 자녀의 ID로 로그인하여 기사를 작성할 수 있습니다.",
              // isOk: () => window.location.href = `/login/student`,
              isOk: () => window.location.href = "/",
              // isOk: () => setStateValue("alert", null),
              isCancel: null,
            });

          }


      } catch (error) {

        console.log(error);
        setStateValue("alert", {
          title: "결제를 실패하였습니다.",
          sub: error.response.data.message,
          isOk: () => setStateValue("alert", null),
          isCancel: null,
        });

      } finally {
        setLoading(false);
      }
    };







    // 결제하기
  const addPayment2 = async () => {
    if (inputData.child === []) {
      setStateValue("alert", {
        title: "아이를 선택해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    if (!state.data_info.user_card) {
      setStateValue("alert", {
        title: "카드 등록을 해주세요.",
        sub: null,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
      return;
    }
    const json = {
      child: inputData.child,
    };
    try {
      const { data } = await paymentApi.setPayment(
        { id: parseInt(url.id) },
        json
      );
      setStateValue("step", 2);
      setState({
        ...state,
        payment_info: data.data,
        step: 2,
        modal: { open: false, type: "", id: "" },
      });
    } catch (error) {
      console.log(error);
      setStateValue("alert", {
        title: "결제가 실패됐어요.",
        sub: error.response.data.message,
        isOk: () => setStateValue("alert", null),
        isCancel: null,
      });
    } finally {
      setLoading(false);
    }
  };











  // keyword로 검색 결과 셋팅
  const searchList = () => {
    if (state.search_keyword === "" || state.search_keyword.length < 2) {
      alert("키워드는 두글자 이상으로 검색 바랍니다.");
      return;
    } else {
      getList();
    }
  };

  // state 셋팅
  const setStateValue = (key, value) => {
    setState({ ...state, [key]: value });
  };
  // state input 셋팅
  const setInputStateValue = (key, value) => {
    if (key === "child") {
      if (inputData.child.filter((i) => i === value).length > 0) {
        setInputData({
          ...inputData,
          child: inputData.child.filter((i) => i !== value),
        });
      } else {
        setInputData({
          ...inputData,
          child: inputData.child.concat(value),
        });
      }
    } else if (key.includes("card_num")) {
      if (inputData[key].length > 3) {
        return;
      } else {
        setInputData({ ...inputData, [key]: value });
      }
    } else {
      setInputData({ ...inputData, [key]: value });
    }
  };

  // 모달  on/off 기능
  const setModalData = (open, type, id) => {
    setState({ ...state, modal: { open: open, type: type, id: id } });
    // type : info /cancel
  };

  // 페이징처리
  const paging = ({ target: { value } }) => {
    setCurrentPage(value);
  };
  return {
    contentOpen,
    state,
    data: { currentPage, inputData, filterList },
    func: {
      setStateValue,
      setInputStateValue,
      setModalData,
      paging,
      searchList,
      onClickLink,
      setContentsOnoff,
      addPayment,
      setPaymentCard,
    },
  };
};

export default SubscribeLogic;
